<template>
  <div class="c19">
    <p class="c12"><span class="c10">Termos de Uso</span></p>
    <p class="c8"><span class="c11">Atualizado 24 de Janeiro de 2019</span></p>
    <p class="c9"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"><img
      alt="" src="../assets/policies/image1.jpg"
      style="width: 220.00px; margin-left: -29.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span></p>
    <p class="c8"><span class="c15">Escrito(a) por&nbsp;</span><span class="c4">Fabr&iacute;cio Hertz</span><span
      class="c15 c16">&nbsp;<br></span></p>
    <p class="c6"><span class="c17">Este &ldquo;Termo e Condi&ccedil;&otilde;es de Uso&rdquo; (TCDU) estabelece os termos e condi&ccedil;&otilde;es sob os quais a HORUS AERONAVES LTDA - EPP / CNPJ: 19.664.563/0001-02, com sede na cidade de Florian&oacute;polis &ndash; SC na Rod. Jos&eacute; Carlos Daux, 600 - Bairro Jo&atilde;o Paulo &ndash; CEP 88030-000 (MAPPA) fornece acesso e regula o uso do site localizado em&nbsp;</span><span
      class="c14"><a class="c13"
                     href="https://www.mappa.ag">www.mappa.ag</a></span><span
      class="c2">&nbsp;e outros sites sob o mesmo dom&iacute;nio (SITE) e as aplica&ccedil;&otilde;es SaaS, ferramentas , aplica&ccedil;&otilde;es mobile, aplica&ccedil;&otilde;es em geral e servi&ccedil;os correlatos (Servi&ccedil;o).</span>
    </p>
    <p class="c0"><span class="c2">Usando o SITE e/ou o Servi&ccedil;o, ou clicando &quot;aceito&quot;, significa que o USU&Aacute;RIO est&aacute; de acordo com este TCDU, e eventuais altera&ccedil;&otilde;es unilaterais feitas pela MAPPA. Se o USU&Aacute;RIO n&atilde;o concordar com este TCDU, ele n&atilde;o pode usar o SITE ou o Servi&ccedil;o.</span>
    </p>
    <p class="c7"><span class="c5">INTRODU&Ccedil;&Atilde;O</span></p>
    <p class="c0"><span class="c2">Agradecemos por escolher a MAPPA. Ao se inscrever ou usar, de outra forma, o servi&ccedil;o, os sites e os aplicativos de software da MAPPA, acessar qualquer conte&uacute;do ou material que seja disponibilizado pela MAPPA por meio do Servi&ccedil;o, voc&ecirc; estar&aacute; celebrando um contrato vinculativo com a entidade indicada no preambulo desse documento.</span>
    </p>
    <p class="c0"><span class="c2">Se voc&ecirc; quiser analisar os termos dos Acordos, a vers&atilde;o efetiva dos Acordos poder&aacute; ser encontrada no site da MAPPA. Voc&ecirc; confirma que leu e compreendeu os Acordos, que aceita esses Acordos e concorda em cumpri-los. Se voc&ecirc; n&atilde;o concordar com (ou n&atilde;o puder cumprir) os Acordos, ent&atilde;o n&atilde;o poder&aacute; usar os Servi&ccedil;os da MAPPA ou acessar nenhum conte&uacute;do.</span>
    </p>
    <p class="c0"><span class="c2">Leia os termos dos Acordos atentamente. Eles abrangem informa&ccedil;&otilde;es importantes sobre os servi&ccedil;os da MAPPA prestados para voc&ecirc; e quaisquer encargos, impostos e tarifas que lhe ser&atilde;o cobrados. Os Acordos incluem informa&ccedil;&otilde;es sobre futuras altera&ccedil;&otilde;es em seus termos, renova&ccedil;&otilde;es autom&aacute;ticas, limita&ccedil;&otilde;es de responsabilidade, informa&ccedil;&otilde;es de privacidade.</span>
    </p>
    <p class="c7"><span class="c5">DAS MODIFICA&Ccedil;&Otilde;ES DO TERMO</span></p>
    <p class="c0"><span class="c2">A MAPPA poder&aacute;, a qualquer tempo modificar, alterar ou excluir parte do conte&uacute;do do presente TCDU visando seu aprimoramento e melhoria dos servi&ccedil;os prestados, com ou sem aviso pr&eacute;vio.</span>
    </p>
    <p class="c0"><span class="c2">O USU&Aacute;RIO reconhece e aceita que &eacute; de sua responsabilidade rever o TCDU periodicamente para verificar qualquer modifica&ccedil;&atilde;o. O acesso cont&iacute;nuo ou o uso dos Servi&ccedil;os ap&oacute;s essa publica&ccedil;&atilde;o constituem o seu consentimento em ficar vinculado pelos Termos de Servi&ccedil;o modificados.</span>
    </p>
    <p class="c7"><span class="c5">ELEGIBILIDADE</span></p>
    <p class="c0"><span class="c2">O USU&Aacute;RIO n&atilde;o pode usar o Servi&ccedil;o nem aceitar este TCDU se n&atilde;o tiver dezoito anos de idade completos, nem gozar de plena capacidade legal. Ao aceitar este TCDU o USU&Aacute;RIO garante que:</span>
    </p>
    <p class="c0"><span class="c2">a. n&atilde;o foi anteriormente suspenso ou removido do servi&ccedil;o;</span></p>
    <p class="c0" id="h.gjdgxs"><span class="c2">b. seu registro e o seu uso do servi&ccedil;o s&atilde;o compat&iacute;veis com todas as regula&ccedil;&otilde;es leis aplic&aacute;veis.</span>
    </p>
    <p class="c7"><span class="c5">RESPONSABILIDADE DO USU&Aacute;RIO</span></p>
    <p class="c0"><span class="c2">&nbsp;O USU&Aacute;RIO &eacute; unicamente respons&aacute;vel por garantir o cumprimento das aplica&ccedil;&otilde;es legais requeridas para opera&ccedil;&atilde;o de qualquer aeronave, incluindo qualquer requerimento que seja obrigat&oacute;rio para a realiza&ccedil;&atilde;o de um voo seguro de acordo com as leis locais de onde o voo ser&aacute; realizado. O USU&Aacute;RIO deve, a todo tempo, cumprir com todas as aplica&ccedil;&otilde;es legais e regulamentos locais, estaduais, nacionais ou internacionais relacionadas com a opera&ccedil;&atilde;o de dispositivos a&eacute;reos n&atilde;o tripulados e do Servi&ccedil;o em seu territ&oacute;rio de opera&ccedil;&atilde;o, incluindo qualquer aplica&ccedil;&atilde;o legal que diz respeito &agrave; privacidade e restri&ccedil;&otilde;es de propriedade. O USU&Aacute;RIO obter&aacute; e manter&aacute; todas as licen&ccedil;as necess&aacute;rias, consentimentos e autoriza&ccedil;&otilde;es de qualquer natureza, para a opera&ccedil;&atilde;o de dispositivos a&eacute;reos n&atilde;o tripulados. &nbsp;</span>
    </p>
    <p class="c0"><span class="c2">Ao utilizar o programa de automa&ccedil;&atilde;o parcial de voo o USU&Aacute;RIO continuar&aacute; sendo o &uacute;nico respons&aacute;vel pelo voo e eventuais danos causados, ficando claro que este deve permanecer no comando da aeronave durante todo o per&iacute;odo. A MAPPA n&atilde;o poder&aacute; ser responsabilizada por qualquer falha no programa de automa&ccedil;&atilde;o parcial de voo, devendo qualquer situa&ccedil;&atilde;o de erro ou falha ser corrigida manualmente pelo USU&Aacute;RIO.&nbsp;</span>
    </p>
    <p class="c7"><span class="c5">&nbsp;POL&Iacute;TICA DE PRIVACIDADE</span></p>
    <p class="c0"><span class="c17">Ao aceitar este TCDU ou usando o SITE ou o Servi&ccedil;o, o USU&Aacute;RIO declara que leu, compreendeu e concorda com nossa Pol&iacute;tica de Privacidade, al&eacute;m deste TCDU. A MAPPA pode revisar a&nbsp;</span><span
      class="c1"><a class="c13"
                    href="/privacy-policy">Pol&iacute;tica de Privacidade</a></span><span
      class="c2">&nbsp;a qualquer momento, e as novas vers&otilde;es ser&atilde;o publicadas no SITE. Se a qualquer momento o USU&Aacute;RIO n&atilde;o concordar com qualquer parte da Pol&iacute;tica de Privacidade, o USU&Aacute;RIO deve imediatamente parar de usar o SITE e/ou o Servi&ccedil;o.</span>
    </p>
    <p class="c7"><span class="c5">PAGAMENTO&nbsp;</span></p>
    <p class="c0"><span class="c2">O acesso ao Servi&ccedil;o requer que o USU&Aacute;RIO pague um valor mensal (mensalidade). O acesso a certos recursos do Site pode exigir o pagamento de valores adicionais (processamento de imagens e servi&ccedil;os adicionais).</span>
    </p>
    <p class="c0"><span class="c2">Todos os planos de pagamento ser&atilde;o feitos mediante cobran&ccedil;a em Boletos ou Cart&atilde;o de Cr&eacute;dito, que dever&aacute; ser cadastrado pelo USU&Aacute;RIO. O USU&Aacute;RIO n&atilde;o poder&aacute; cadastrar em sua conta Cart&otilde;es de Cr&eacute;dito de terceiros, sob pena de exclus&atilde;o do SITE e demais medidas legais cab&iacute;veis.&nbsp;</span>
    </p>
    <p class="c0"><span class="c2">Para os planos mensais, o servi&ccedil;o ser&aacute; cobrado automaticamente, antecipadamente e n&atilde;o &eacute; reembols&aacute;vel. N&atilde;o haver&aacute; reembolsos ou cr&eacute;ditos para meses parciais de uso do servi&ccedil;o, melhoria ou &ldquo;rebaixamento&rdquo; do servi&ccedil;o, o plano sempre ficar&aacute; dispon&iacute;vel para uso at&eacute; o encerramento do per&iacute;odo pago antecipadamente.</span>
    </p>
    <p class="c0"><span class="c2">O pagamento para a MAPPA ser&aacute; automaticamente renovado ao final do per&iacute;odo de assinatura, a menos que o USU&Aacute;RIO cancele sua Assinatura Paga por meio da p&aacute;gina de assinatura antes do final do per&iacute;odo da assinatura atual. O cancelamento entrar&aacute; em vigor no dia seguinte ao &uacute;ltimo dia do per&iacute;odo de assinatura atual e o USU&Aacute;RIO deixar&aacute; de ter acesso ao Servi&ccedil;o. No entanto, se o USU&Aacute;RIO cancelar a Assinatura antes do final do per&iacute;odo de assinatura atual, n&atilde;o ser&aacute; reembolsada nenhuma tarifa de assinatura que j&aacute; tiver sido paga e o plano permanecer&aacute; dispon&iacute;vel at&eacute; o final do per&iacute;odo pago antecipadamente.&nbsp;</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA poder&aacute;, de tempos em tempos, alterar o pre&ccedil;o dos Planos de Assinatura e outros servi&ccedil;os e informar&aacute; quaisquer altera&ccedil;&otilde;es de pre&ccedil;o ao USU&Aacute;RIO com anteced&ecirc;ncia de ao menos trinta dias. As altera&ccedil;&otilde;es de pre&ccedil;os das Planos de Assinatura e outros servi&ccedil;os entrar&atilde;o em vigor no in&iacute;cio do pr&oacute;ximo per&iacute;odo de assinatura, depois da data da mudan&ccedil;a de pre&ccedil;o. O USU&Aacute;RIO aceitar&aacute; o novo pre&ccedil;o ao continuar usando o Servi&ccedil;o depois que a altera&ccedil;&atilde;o de pre&ccedil;o entrar em vigor. Se o USU&Aacute;RIO n&atilde;o concordar com as mudan&ccedil;as de pre&ccedil;os, ter&aacute; o direito de cancelar a assinatura do Servi&ccedil;o antes da cobran&ccedil;a da primeira mensalidade com o novo valor.&nbsp;</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA poder&aacute; abrir em determinadas situa&ccedil;&otilde;es per&iacute;odos de teste gratuito para novos usu&aacute;rios, sendo certo que o acesso destes usu&aacute;rios poder&aacute; ser cancelado a qualquer momento pela MAPPA sem qualquer aviso pr&eacute;vio. As especifica&ccedil;&otilde;es sobre o per&iacute;odo de teste gratuito ser&atilde;o divulgadas pela plataforma no momento da ades&atilde;o e cadastro do novo usu&aacute;rio.</span>
    </p>
    <p class="c7"><span class="c5">&nbsp;ACESSO &Agrave; INTERNET</span></p>
    <p class="c0"><span class="c2">A MAPPA n&atilde;o se responsabiliza por falhas de comunica&ccedil;&atilde;o, de internet, etc. O USU&Aacute;RIO &eacute; inteiramente respons&aacute;vel pela conex&atilde;o &agrave; Internet forte o suficiente para a plena opera&ccedil;&atilde;o do SITE e/ou do Servi&ccedil;o.</span>
    </p>
    <p class="c7"><span class="c5">CONTAS</span></p>
    <p class="c0"><span class="c2">O SITE e/ou Servi&ccedil;o poder&atilde;o ser utilizados somente por pessoas maiores de 18 (dezoito) anos que cumpram todos os requisitos previstos no TCDU. Al&eacute;m das pessoas consideradas por lei como incapazes, tamb&eacute;m ser&atilde;o impossibilitados de utilizar os servi&ccedil;os pessoas que tenham sido inabilitadas previamente.&nbsp;</span>
    </p>
    <p class="c0"><span class="c2">O cadastro &eacute; gratuito, no entanto, os servi&ccedil;os oferecidos pela MAPPA ser&atilde;o cobrados de acordo com o estabelecido neste TCDU.</span>
    </p>
    <p class="c0"><span class="c2">Cada USU&Aacute;RIO dever&aacute; ter apenas um cadastro no SITE. Caso seja identificada a exist&ecirc;ncia de cadastros duplicados, a MAPPA poder&aacute; inabilitar de forma definitiva todos os cadastros do USU&Aacute;RIO no SITE, independentemente de qualquer aviso ou notifica&ccedil;&atilde;o.</span>
    </p>
    <p class="c0"><span class="c2">O USU&Aacute;RIO declara que todas as informa&ccedil;&otilde;es que forneceu &agrave; MAPPA para fins de cria&ccedil;&atilde;o de uma conta (Informa&ccedil;&otilde;es de Registro) s&atilde;o verdadeiras, precisas, atuais e completas e sua falha em fornecer tal informa&ccedil;&atilde;o constituir&aacute; uma viola&ccedil;&atilde;o deste TCDU e poder&aacute; resultar na rescis&atilde;o imediata de sua conta. O USU&Aacute;RIO atualizar&aacute; suas Informa&ccedil;&otilde;es de Registro para mant&ecirc;-las verdadeiras, precisas, atuais e completas; e manter&aacute; a confidencialidade de sua senha.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;Ao criar uma Conta, o USU&Aacute;RIO n&atilde;o deve se passar por outra pessoa, nem usar um nome sujeito aos direitos de qualquer outra pessoa sem autoriza&ccedil;&atilde;o. O USU&Aacute;RIO tamb&eacute;m se compromete a n&atilde;o usar endere&ccedil;o de e-mail ou nome de usu&aacute;rio que seja ofensivo ou inadequado; nem permitir que qualquer outra pessoa use sua Conta.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;O USU&Aacute;RIO entende e concorda que ser&aacute; respons&aacute;vel por todas as atividades que ocorrem sob qualquer Conta criada para seu uso, mesmo se tais atividades n&atilde;o foram por ele cometidas. A MAPPA n&atilde;o se responsabiliza por qualquer perda ou dano resultante da sua falha em manter a confidencialidade da sua senha.</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA poder&aacute; recusar qualquer solicita&ccedil;&atilde;o de cadastro, bem como cancelar um cadastro previamente aceito que esteja em desacordo com este TCDU. Fica estabelecido que o cadastramento do interessado, uma vez aprovado e confirmado o pagamento, permitir&aacute; a utiliza&ccedil;&atilde;o dos servi&ccedil;os do SITE dispon&iacute;veis no plano contratado.</span>
    </p>
    <p class="c0"><span class="c2">O conte&uacute;do das informa&ccedil;&otilde;es inseridas no cadastro &eacute; de inteira responsabilidade do USU&Aacute;RIO, n&atilde;o sendo a MAPPA respons&aacute;vel pela corre&ccedil;&atilde;o ou verifica&ccedil;&atilde;o dos dados pessoais. O USU&Aacute;RIO garante e responde, em qualquer caso, civil e criminalmente, pela veracidade, exatid&atilde;o e autenticidade dos dados pessoais cadastrados.</span>
    </p>
    <p class="c0"><span class="c2">Independente do disposto acima, a MAPPA poder&aacute;, a qualquer tempo e a seu exclusivo crit&eacute;rio, verificar os dados pessoais podendo para tanto, solicitar documentos e comprovantes do USU&Aacute;RIO para verifica&ccedil;&atilde;o das informa&ccedil;&otilde;es fornecidas.</span>
    </p>
    <p class="c0"><span class="c2">Sem preju&iacute;zo de outras medidas, a MAPPA poder&aacute;, a qualquer tempo e, a seu exclusivo crit&eacute;rio, advertir, suspender ou cancelar, tempor&aacute;ria ou definitivamente, o cadastro de um USU&Aacute;RIO, suspendendo a presta&ccedil;&atilde;o de seus servi&ccedil;os se: (i) o USU&Aacute;RIO descumprir as disposi&ccedil;&otilde;es do presente TCDU; (ii) descumprir com seus deveres de USU&Aacute;RIO; (iii) praticar atos fraudulentos ou dolosos; (iv) n&atilde;o puder ser verificada a identidade do USU&Aacute;RIO ou se verificada que qualquer informa&ccedil;&atilde;o fornecida por ele est&aacute; incorreta; (v) qualquer atitude do USU&Aacute;RIO tenha causado algum dano a terceiros ou ao pr&oacute;prio SITE ou tenham a potencialidade de assim o fazer.</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA RESERVA-SE O DIREITO DE EXCLUIR OU BLOQUEAR DADOS DO USU&Aacute;RIO SEM ACESSO POR UM PER&Iacute;ODO SUPERIOR A 6 (SEIS) MESES, SEM PR&Eacute;VIO AVISO.&nbsp;</span>
    </p>
    <p class="c0"><span class="c2">O USU&Aacute;RIO poder&aacute;, a qualquer momento, solicitar a exclus&atilde;o de seu cadastro. Caso n&atilde;o existam pend&ecirc;ncias conhecidas, a MAPPA ir&aacute; realizar a exclus&atilde;o do cadastro, mantendo em seus arquivos as informa&ccedil;&otilde;es sobre transa&ccedil;&otilde;es realizadas.</span>
    </p>
    <p class="c0"><span class="c2">Em caso de perda, extravio ou suspeita de utiliza&ccedil;&atilde;o indevida de sua conta, login ou senha, o USU&Aacute;RIO dever&aacute; notificar a MAPPA imediatamente. Neste caso, a MAPPA ir&aacute; bloquear o respectivo cadastro at&eacute; identificar a melhor maneira de solucionar o problema.</span>
    </p>
    <p class="c0"><span class="c2">Em nenhuma hip&oacute;tese ser&aacute; permitida a cess&atilde;o, venda, aluguel ou outra forma de transfer&ecirc;ncia da conta de um USU&Aacute;RIO a terceiros, assim como a cria&ccedil;&atilde;o de novos cadastros a USU&Aacute;RIO cujo cadastro original tenha sido cancelado unilateralmente pela MAPPA.</span>
    </p>
    <p class="c0"><span class="c2">Ser&atilde;o eliminados apelidos ou nomes de usu&aacute;rio que guardem qualquer semelhan&ccedil;a com o nome &ldquo;MAPPA&rdquo;, bem como os considerados ofensivos, ou que contenham dados pessoais do USU&Aacute;RIO ou alguma propaganda, URL ou endere&ccedil;o eletr&ocirc;nico.</span>
    </p>
    <p class="c7"><span class="c5">&nbsp;LICEN&Ccedil;A LIMITADA PARA UTILIZAR O SITE E O SERVI&Ccedil;O</span></p>
    <p class="c0"><span class="c2">&nbsp;Nos termos deste TCDU, a MAPPA concede ao USU&Aacute;RIO licen&ccedil;a limitada, n&atilde;o exclusiva, pessoal e intransfer&iacute;vel para acessar e usar o SITE e/ou o Servi&ccedil;o. O SITE e/ou o Servi&ccedil;o somente podem ser utilizados para fins pessoais e n&atilde;o comerciais.</span>
    </p>
    <p class="c0"><span class="c2">A interface, o conte&uacute;do, o arranjo e a apar&ecirc;ncia do SITE e/ou Servi&ccedil;o, incluindo, as marcas registradas MAPPA e logotipos, desenhos, textos, ilustra&ccedil;&otilde;es, imagens, dados, bot&otilde;es, interfaces de usu&aacute;rio, c&oacute;digos fonte, c&oacute;digos de objeto, know-how, produtos, programas, informa&ccedil;&otilde;es e outros conte&uacute;dos e qualquer compila&ccedil;&atilde;o dos itens acima s&atilde;o propriedade da MAPPA, exceto quando indicado de forma diversa, e s&atilde;o protegidos contra c&oacute;pias ou imita&ccedil;&otilde;es.&nbsp;</span>
    </p>
    <p class="c7"><span class="c5">DA VIOLA&Ccedil;&Atilde;O DO SISTEMA E DO SIGILO DE INFORMA&Ccedil;&Otilde;ES</span>
    </p>
    <p class="c0"><span class="c2">N&atilde;o &eacute; permitida a utiliza&ccedil;&atilde;o de nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades e opera&ccedil;&otilde;es do SITE e/ou Servi&ccedil;o, descri&ccedil;&otilde;es, contas ou seus bancos de dados. Qualquer intromiss&atilde;o, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibi&ccedil;&otilde;es estipuladas neste TCDU, tornar&atilde;o o respons&aacute;vel pass&iacute;vel das a&ccedil;&otilde;es legais pertinentes, bem como das san&ccedil;&otilde;es aqui previstas, sendo ainda respons&aacute;vel pelas indeniza&ccedil;&otilde;es por eventuais danos causados.</span>
    </p>
    <p class="c0"><span class="c2">Toda informa&ccedil;&atilde;o ou DADOS PESSOAIS inseridos pelo USU&Aacute;RIO no SITE e/ou Servi&ccedil;o &eacute; armazenada em servidores ou meios magn&eacute;ticos, &oacute;pticos ou de acordo com a evolu&ccedil;&atilde;o tecnol&oacute;gica, e a MAPPA se obriga a adotar as medidas necess&aacute;rias para manter o sigilo e a seguran&ccedil;a das informa&ccedil;&otilde;es, ficando estabelecido, entretanto, que n&atilde;o responder&aacute; por quaisquer preju&iacute;zos ocasionados pela viola&ccedil;&atilde;o das medidas necess&aacute;rias de seguran&ccedil;a e sigilo por terceiros que utilizem as redes p&uacute;blicas ou a internet, subvertendo os sistemas de seguran&ccedil;a para acessar as informa&ccedil;&otilde;es de usu&aacute;rios.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;O USU&Aacute;RIO se compromete a n&atilde;o violar qualquer lei ou regulamento aplic&aacute;vel em conex&atilde;o com seu uso do SITE e/ou Servi&ccedil;o.&nbsp;</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA pode cancelar a assinatura de um usu&aacute;rio se achar que o mesmo n&atilde;o est&aacute; utilizando a plataforma dentro do que consideramos um uso justo. Nesse momento, o usu&aacute;rio ser&aacute; notificado, seu plano cancelado e sem direito de reembolso.</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA n&atilde;o controla o conte&uacute;do (imagens, dados, hist&oacute;rico de localiza&ccedil;&atilde;o, etc.) enviado pelo USU&Aacute;RIO para o SITE e/ou Servi&ccedil;o, e, portanto, n&atilde;o garante e n&atilde;o ser&aacute; respons&aacute;vel por tais conte&uacute;dos.&nbsp;</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA N&Atilde;O SE RESPONSABILIZA OU FORNECE GARANTIAS DOS DADOS GERADOS ATRAV&Eacute;S DO SITE E/OU SERVI&Ccedil;O QUANTO &Agrave; SUA ACUR&Aacute;CIA, PRECIS&Atilde;O, RESOLU&Ccedil;&Atilde;O, AUS&Ecirc;NCIA DE ERROS E CONFIABILIDADE.</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA N&Atilde;O SE RESPONSABILIZA PELA QUALIDADE DOS MATERIAIS E DADOS CAPTADOS E PROVIDOS PELO USU&Aacute;RIO PARA PROCESSAMENTO ATRAV&Eacute;S DO SITE E/OU SERVI&Ccedil;O. A BAIXA QUALIDADE DESTES DADOS PODE GERAR DADOS IMPRECISOS, SEM RESOLU&Ccedil;&Atilde;O, SEM ACUR&Aacute;CIA, INFORMA&Ccedil;&Otilde;ES QUE POSSAM N&Atilde;O CONDIZER COM A REALIDADE, ENTRE OUTROS PROBLEMAS, MOTIVO PELO QUAL A MAPPA N&Atilde;O SE RESPONSABILIZA PELA UTILIZA&Ccedil;&Atilde;O DAS INFORMA&Ccedil;&Otilde;ES GERADAS PELO SITE E/OU SERVI&Ccedil;O PELO USU&Aacute;RIO.</span>
    </p>
    <p class="c0"><span class="c2">Todos os coment&aacute;rios, sugest&otilde;es, ideias e outros envios que o USU&Aacute;RIO divulgar, enviar ou oferecer relacionado ao uso do SITE ou Servi&ccedil;o, constituir&aacute; numa cess&atilde;o de todos os direitos de propriedade intelectual, e tais sugest&otilde;es se tornar&atilde;o de propriedade exclusiva da MAPPA.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;O USU&Aacute;RIO fornece seu consentimento irrevog&aacute;vel para monitoramento, acesso e divulga&ccedil;&atilde;o de seus coment&aacute;rios e atividades no SITE e no Servi&ccedil;o.<br>&nbsp;</span>
    </p>
    <p class="c7"><span class="c5">APLICATIVOS DE TERCEIROS</span></p>
    <p class="c0"><span class="c2">O SITE e o Servi&ccedil;o est&atilde;o integrados com aplicativos, sites e servi&ccedil;os de terceiros (&quot;Aplicativos de Terceiros&quot;) para disponibilizar ao USU&Aacute;RIO conte&uacute;do, produtos e/ou servi&ccedil;os. Esses Aplicativos de Terceiros poder&atilde;o ter seus pr&oacute;prios termos e condi&ccedil;&otilde;es de uso e pol&iacute;ticas de privacidade, e o uso desses Aplicativos de Terceiros ser&aacute; regido por, e estar&aacute; sujeito a, tais termos e condi&ccedil;&otilde;es, e &agrave;s pol&iacute;ticas de privacidade. O USU&Aacute;RIO compreende e concorda que a MAPPA n&atilde;o endossa e n&atilde;o &eacute; respons&aacute;vel pelo comportamento, recursos ou conte&uacute;do de qualquer Aplicativo de Terceiro, ou por qualquer transa&ccedil;&atilde;o que voc&ecirc; possa fazer com o provedor de tais Aplicativos de Terceiros.</span>
    </p>
    <p class="c7"><span class="c5">SERVI&Ccedil;OS DE TERCEIROS</span></p>
    <p class="c0"><span class="c2">&nbsp;A MAPPA pode fornecer ferramentas atrav&eacute;s do servi&ccedil;o que permitem exportar informa&ccedil;&otilde;es, incluindo o conte&uacute;do de usu&aacute;rio, para servi&ccedil;os de terceiros. Ao usar uma dessas ferramentas, o USU&Aacute;RIO aceita que a MAPPA possa transferir aquelas informa&ccedil;&otilde;es para as aplica&ccedil;&otilde;es do servi&ccedil;o de terceiro. Estes servi&ccedil;os n&atilde;o est&atilde;o sobre o controle da MAPPA, e portanto, esta n&atilde;o ser&aacute; respons&aacute;vel por qualquer uso de informa&ccedil;&otilde;es do USU&Aacute;RIO.</span>
    </p>
    <p class="c7"><span class="c5">REPRESENTA&Ccedil;&Atilde;O E GARANTIA DE CONTE&Uacute;DO DE USU&Aacute;RIO</span>
    </p>
    <p class="c0"><span class="c2">O USU&Aacute;RIO &eacute; unicamente respons&aacute;vel pelas suas informa&ccedil;&otilde;es de usu&aacute;rio e pelas consequ&ecirc;ncias de postar ou publicar essas informa&ccedil;&otilde;es. Ao publicar informa&ccedil;&otilde;es de usu&aacute;rio, este assume e garante que: &eacute; o criador e possuidor das informa&ccedil;&otilde;es, ou possui licen&ccedil;as necess&aacute;rias, direitos, consentimentos e permiss&otilde;es para usa-las e autorizar a MAPPA e os usu&aacute;rios do servi&ccedil;o a usar e distribui-las; suas informa&ccedil;&otilde;es e o uso destas, como aqui contemplado, n&atilde;o ir&aacute;: (i) infringir qualquer direito de terceiro, incluindo qualquer propriedade intelectual, privacidade ou direito de propriedade; ou (ii) caluniar, difamar ou invadir o direito &agrave; privacidade, publicidade ou outros direitos de propriedade de qualquer pessoa.</span>
    </p>
    <p class="c0"><span class="c2">A MAPPA n&atilde;o esta sob nenhuma obriga&ccedil;&atilde;o de editar ou controlar informa&ccedil;&otilde;es de usu&aacute;rio que este ou outros usu&aacute;rios postarem ou publicarem, e n&atilde;o ser&aacute; de modo algum respons&aacute;vel pelo conte&uacute;do do usu&aacute;rio. A MAPPA pode, no entanto, a qualquer tempo e sem pr&eacute;vio aviso, ocultar, remover, editar ou bloquear qualquer informa&ccedil;&atilde;o de usu&aacute;rio que no julgamento da MAPPA violar esses termos ou que for censur&aacute;vel. O USU&Aacute;RIO aceita renunciar e renuncia qualquer rem&eacute;dio legal ou equitativo que voc&ecirc; possa ter contra a MAPPA a respeito das informa&ccedil;&otilde;es de usu&aacute;rios.</span>
    </p>
    <p class="c7"><span class="c5">LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE</span></p>
    <p class="c0"><span class="c2">&nbsp;A MAPPA n&atilde;o se responsabiliza por v&iacute;cios ou defeitos t&eacute;cnicos e/ou operacionais oriundos do sistema do usu&aacute;rio ou de terceiros, inclusive relativos a pagamentos e transfer&ecirc;ncias.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;A MAPPA n&atilde;o se responsabiliza por qualquer dano, preju&iacute;zo ou perda sofridos pelo USU&Aacute;RIO em raz&atilde;o de falhas na internet, no sistema ou no servidor utilizado pelo USU&Aacute;RIO, ou aqueles decorrentes de condutas de terceiros, de caso fortuito ou de for&ccedil;a maior. A MAPPA tamb&eacute;m n&atilde;o ser&aacute; respons&aacute;vel por qualquer v&iacute;rus ou malware que possa atacar o equipamento do USU&Aacute;RIO em decorr&ecirc;ncia do acesso, utiliza&ccedil;&atilde;o ou navega&ccedil;&atilde;o na internet ou como consequ&ecirc;ncia da transfer&ecirc;ncia de dados, arquivos, imagens, textos ou &aacute;udio.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;O USU&Aacute;RIO indenizar&aacute; a MAPPA, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por seu descumprimento do presente TCDU e demais pol&iacute;ticas do SITE e Servi&ccedil;o, ou pela viola&ccedil;&atilde;o de qualquer lei ou direito, incluindo honor&aacute;rios advocat&iacute;cios.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;Ao aceitar o presente TCDU, o USU&Aacute;RIO reconhece e concorda que a &uacute;nica medida cab&iacute;vel por quaisquer problemas ou insatisfa&ccedil;&otilde;es com o SITE ou qualquer parte do servi&ccedil;o &eacute; encerrar a sua conta e descontinuar o uso.</span>
    </p>
    <p class="c0"><span class="c2">Na medida m&aacute;xima permitida pela lei, em nenhuma circunst&acirc;ncia a MAPPA, seus respons&aacute;veis, acionistas, funcion&aacute;rios, agentes, diretores, subsidi&aacute;rias, afiliadas, sucessores, cession&aacute;rios, fornecedores ou licenciadores ser&atilde;o respons&aacute;veis por (a) quaisquer danos indiretos, especiais, incidentais, punitivos, exemplares ou consequentes; (b) qualquer perda de uso, dados, neg&oacute;cios ou lucros (sejam diretos ou indiretos) decorrentes, em todos os casos, do uso ou da incapacidade de usar o Servi&ccedil;o, os aplicativos de terceiros ou o conte&uacute;do de aplicativos de terceiros, independentemente da teoria legal; ou (c) responsabilidade agregada quanto a todas as reivindica&ccedil;&otilde;es relacionadas ao Servi&ccedil;o, aplicativos de terceiros ou conte&uacute;do dos aplicativos de terceiros que sejam superiores aos valores pagos pelo USU&Aacute;RIO durante os doze meses anteriores em quest&atilde;o, na medida m&aacute;xima permitida pela lei aplic&aacute;vel.<br><br>O Servi&ccedil;o &eacute; fornecido &quot;no estado em que se encontra&quot; e &quot;como dispon&iacute;vel&quot;. A MAPPA renuncia a todas as representa&ccedil;&otilde;es e garantias, expressas, impl&iacute;citas ou estatut&aacute;rias, n&atilde;o expressamente definidas nestes termos, incluindo as garantias impl&iacute;citas de comercializa&ccedil;&atilde;o, aptid&atilde;o para um prop&oacute;sito espec&iacute;fico e n&atilde;o viola&ccedil;&atilde;o. Adicionalmente, a MAPPA n&atilde;o faz nenhuma representa&ccedil;&atilde;o, garantia ou garantia relativa &agrave; confiabilidade, oportunidade, qualidade, adequa&ccedil;&atilde;o ou disponibilidade dos servi&ccedil;os ou de qualquer servi&ccedil;o ou produto pedido pelo uso dos servi&ccedil;os ou que os servi&ccedil;os ser&atilde;o ininterruptos.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;O USU&Aacute;RIO aceita defender, indenizar e manter a MAPPA e suas afiliadas inofensivas perante qualquer demanda, perda, responsabilidade, reclama&ccedil;&otilde;es ou despesas (incluindo taxas de advogados) feitas contra estas por terceiros devido o uso do Servi&ccedil;o.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;A MAPPA empreender&aacute; esfor&ccedil;os razo&aacute;veis para manter o Servi&ccedil;o operacional. No entanto, determinadas dificuldades t&eacute;cnicas ou manuten&ccedil;&atilde;o poder&atilde;o, de tempos em tempos, resultar em interrup&ccedil;&otilde;es tempor&aacute;rias. Na medida do permitido segundo a lei aplic&aacute;vel, a MAPPA reserva o direito, periodicamente e a qualquer momento, de modificar ou descontinuar, tempor&aacute;ria ou permanentemente, fun&ccedil;&otilde;es e recursos do Servi&ccedil;o, com ou sem aviso, sem ser responsabilizada, com rela&ccedil;&atilde;o a qualquer interrup&ccedil;&atilde;o, modifica&ccedil;&atilde;o ou descontinua&ccedil;&atilde;o do Servi&ccedil;o ou de qualquer fun&ccedil;&atilde;o ou recurso dele. O USU&Aacute;RIO compreende, concorda e aceita que a MAPPA n&atilde;o tem obriga&ccedil;&atilde;o de manter, apoiar, fazer upgrade ou atualizar o Servi&ccedil;o, ou de fornecer todo ou qualquer conte&uacute;do espec&iacute;fico por meio do Servi&ccedil;o.</span>
    </p>
    <p class="c0"><span class="c17">&nbsp;</span><span class="c20">DAS DISPOSI&Ccedil;&Otilde;ES GERAIS</span></p>
    <p class="c0"><span class="c2">&nbsp;Este TCDU n&atilde;o gera nenhum contrato de sociedade, de mandato, franquia ou rela&ccedil;&atilde;o de trabalho.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;O uso comercial do endere&ccedil;o do SITE como marca, nome empresarial ou nome de dom&iacute;nio, bem como os conte&uacute;dos das telas relativas aos servi&ccedil;os do SITE, assim como os programas, bancos de dados, redes, arquivos que permitem que o USU&Aacute;RIO acesse e use sua conta s&atilde;o propriedade da MAPPA e est&atilde;o protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodu&ccedil;&atilde;o total ou parcial dos referidos conte&uacute;dos s&atilde;o proibidos, salvo autoriza&ccedil;&atilde;o expressa da MAPPA.</span>
    </p>
    <p class="c0"><span class="c2">&nbsp;Para obter informa&ccedil;&otilde;es sobre suporte ao cliente com perguntas relacionadas a contas e a pagamentos (&quot;Perguntas de Suporte ao Cliente&quot;), envie um email para nosso departamento de atendimento ao cliente, usando o formul&aacute;rio de contato de Atendimento ao Cliente na se&ccedil;&atilde;o Sobre N&oacute;s em nosso site. Empreenderemos esfor&ccedil;os razo&aacute;veis para responder a todas as Perguntas de Suporte ao Cliente em um per&iacute;odo razo&aacute;vel, mas n&atilde;o fornecemos garantias de qualquer tipo de que as Perguntas de Suporte ao Cliente ser&atilde;o respondidas dentro de qualquer per&iacute;odo espec&iacute;fico e/ou de que poderemos responder satisfatoriamente a tais perguntas.</span>
    </p>
    <p class="c7"><span class="c5">LEI APLIC&Aacute;VEL / JURISDI&Ccedil;&Atilde;O</span></p>
    <p class="c0"><span class="c2">&nbsp;A MAPPA est&aacute; localizada e registrada no Brasil. Ao utilizar o SITE e/ou Servi&ccedil;o o USU&Aacute;RIO ratifica que tem conhecimento de que o servi&ccedil;o est&aacute; sendo prestado no Brasil.</span>
    </p>
    <p class="c0"><span class="c2">Este TCDU ser&aacute; interpretado sempre em Portugu&ecirc;s do Brasil e regido pelas leis vigentes na Rep&uacute;blica Federativa do Brasil. Para dirimir eventuais d&uacute;vidas ou quest&otilde;es relativas ao presente TCDU, as partes elegem o Foro da Comarca de Florian&oacute;polis - SC, com exclus&atilde;o de qualquer outro.</span>
    </p>
    <p class="c18"><span class="c3"></span></p>
  </div>
</template>

<script>
  export default {
    name: "TermsOfUse"
  }
</script>

<style scoped>
  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
  }

  .c14 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Times New Roman"
  }

  .c18 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
  }

  .c10 {
    color: #3a3c4c;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 25pt;
    font-style: normal
  }

  .c11 {
    color: #8f919d;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-style: normal
  }

  .c1 {
    -webkit-text-decoration-skip: none;
    color: #0591dd;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Times New Roman"
  }

  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c5 {
    color: #3a3c4c;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c0 {
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c12 {
    padding-top: 0pt;
    padding-bottom: 12.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c7 {
    padding-top: 0pt;
    padding-bottom: 11.2pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c8 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c6 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c4 {
    font-size: 10pt;
    color: #4f5e6b;
    font-weight: 400
  }

  .c15 {
    font-size: 10pt;
    color: #8f919d;
    font-weight: 400
  }

  .c17 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 400
  }

  .c19 {
    /*background-color: #ffffff;*/
    background-image: url("../assets/bg-neutral.jpg");
    background-size: cover;
    max-width: 100%;
    padding: 70.8pt 85pt 70.8pt 85pt
  }

  .c16 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
  }

  .c20 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 700
  }

  .c13 {
    color: inherit;
    text-decoration: inherit
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
  }

  h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c19" }, [
      _c("p", { staticClass: "c12" }, [
        _c("span", { staticClass: "c10" }, [_vm._v("Termos de Uso")])
      ]),
      _c("p", { staticClass: "c8" }, [
        _c("span", { staticClass: "c11" }, [
          _vm._v("Atualizado 24 de Janeiro de 2019")
        ])
      ]),
      _c("p", { staticClass: "c9" }, [
        _c(
          "span",
          {
            staticStyle: {
              overflow: "hidden",
              display: "inline-block",
              margin: "0.00px 0.00px",
              border: "0.00px solid #000000",
              transform: "rotate(0.00rad) translateZ(0px)",
              "-webkit-transform": "rotate(0.00rad) translateZ(0px)"
            }
          },
          [
            _c("img", {
              staticStyle: {
                width: "220.00px",
                "margin-left": "-29.00px",
                "margin-top": "-0.00px",
                transform: "rotate(0.00rad) translateZ(0px)",
                "-webkit-transform": "rotate(0.00rad) translateZ(0px)"
              },
              attrs: {
                alt: "",
                src: require("../assets/policies/image1.jpg"),
                title: ""
              }
            })
          ]
        )
      ]),
      _c("p", { staticClass: "c8" }, [
        _c("span", { staticClass: "c15" }, [_vm._v("Escrito(a) por ")]),
        _c("span", { staticClass: "c4" }, [_vm._v("Fabrício Hertz")]),
        _c("span", { staticClass: "c15 c16" }, [_vm._v(" "), _c("br")])
      ]),
      _c("p", { staticClass: "c6" }, [
        _c("span", { staticClass: "c17" }, [
          _vm._v(
            "Este “Termo e Condições de Uso” (TCDU) estabelece os termos e condições sob os quais a HORUS AERONAVES LTDA - EPP / CNPJ: 19.664.563/0001-02, com sede na cidade de Florianópolis – SC na Rod. José Carlos Daux, 600 - Bairro João Paulo – CEP 88030-000 (MAPPA) fornece acesso e regula o uso do site localizado em "
          )
        ]),
        _c("span", { staticClass: "c14" }, [
          _c(
            "a",
            { staticClass: "c13", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa.ag")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " e outros sites sob o mesmo domínio (SITE) e as aplicações SaaS, ferramentas , aplicações mobile, aplicações em geral e serviços correlatos (Serviço)."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            'Usando o SITE e/ou o Serviço, ou clicando "aceito", significa que o USUÁRIO está de acordo com este TCDU, e eventuais alterações unilaterais feitas pela MAPPA. Se o USUÁRIO não concordar com este TCDU, ele não pode usar o SITE ou o Serviço.'
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v("INTRODUÇÃO")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Agradecemos por escolher a MAPPA. Ao se inscrever ou usar, de outra forma, o serviço, os sites e os aplicativos de software da MAPPA, acessar qualquer conteúdo ou material que seja disponibilizado pela MAPPA por meio do Serviço, você estará celebrando um contrato vinculativo com a entidade indicada no preambulo desse documento."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Se você quiser analisar os termos dos Acordos, a versão efetiva dos Acordos poderá ser encontrada no site da MAPPA. Você confirma que leu e compreendeu os Acordos, que aceita esses Acordos e concorda em cumpri-los. Se você não concordar com (ou não puder cumprir) os Acordos, então não poderá usar os Serviços da MAPPA ou acessar nenhum conteúdo."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Leia os termos dos Acordos atentamente. Eles abrangem informações importantes sobre os serviços da MAPPA prestados para você e quaisquer encargos, impostos e tarifas que lhe serão cobrados. Os Acordos incluem informações sobre futuras alterações em seus termos, renovações automáticas, limitações de responsabilidade, informações de privacidade."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v("DAS MODIFICAÇÕES DO TERMO")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA poderá, a qualquer tempo modificar, alterar ou excluir parte do conteúdo do presente TCDU visando seu aprimoramento e melhoria dos serviços prestados, com ou sem aviso prévio."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O USUÁRIO reconhece e aceita que é de sua responsabilidade rever o TCDU periodicamente para verificar qualquer modificação. O acesso contínuo ou o uso dos Serviços após essa publicação constituem o seu consentimento em ficar vinculado pelos Termos de Serviço modificados."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v("ELEGIBILIDADE")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O USUÁRIO não pode usar o Serviço nem aceitar este TCDU se não tiver dezoito anos de idade completos, nem gozar de plena capacidade legal. Ao aceitar este TCDU o USUÁRIO garante que:"
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("a. não foi anteriormente suspenso ou removido do serviço;")
        ])
      ]),
      _c("p", { staticClass: "c0", attrs: { id: "h.gjdgxs" } }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "b. seu registro e o seu uso do serviço são compatíveis com todas as regulações leis aplicáveis."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [
          _vm._v("RESPONSABILIDADE DO USUÁRIO")
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " O USUÁRIO é unicamente responsável por garantir o cumprimento das aplicações legais requeridas para operação de qualquer aeronave, incluindo qualquer requerimento que seja obrigatório para a realização de um voo seguro de acordo com as leis locais de onde o voo será realizado. O USUÁRIO deve, a todo tempo, cumprir com todas as aplicações legais e regulamentos locais, estaduais, nacionais ou internacionais relacionadas com a operação de dispositivos aéreos não tripulados e do Serviço em seu território de operação, incluindo qualquer aplicação legal que diz respeito à privacidade e restrições de propriedade. O USUÁRIO obterá e manterá todas as licenças necessárias, consentimentos e autorizações de qualquer natureza, para a operação de dispositivos aéreos não tripulados. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Ao utilizar o programa de automação parcial de voo o USUÁRIO continuará sendo o único responsável pelo voo e eventuais danos causados, ficando claro que este deve permanecer no comando da aeronave durante todo o período. A MAPPA não poderá ser responsabilizada por qualquer falha no programa de automação parcial de voo, devendo qualquer situação de erro ou falha ser corrigida manualmente pelo USUÁRIO. "
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v(" POLÍTICA DE PRIVACIDADE")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c17" }, [
          _vm._v(
            "Ao aceitar este TCDU ou usando o SITE ou o Serviço, o USUÁRIO declara que leu, compreendeu e concorda com nossa Política de Privacidade, além deste TCDU. A MAPPA pode revisar a "
          )
        ]),
        _c("span", { staticClass: "c1" }, [
          _c("a", { staticClass: "c13", attrs: { href: "/privacy-policy" } }, [
            _vm._v("Política de Privacidade")
          ])
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " a qualquer momento, e as novas versões serão publicadas no SITE. Se a qualquer momento o USUÁRIO não concordar com qualquer parte da Política de Privacidade, o USUÁRIO deve imediatamente parar de usar o SITE e/ou o Serviço."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v("PAGAMENTO ")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O acesso ao Serviço requer que o USUÁRIO pague um valor mensal (mensalidade). O acesso a certos recursos do Site pode exigir o pagamento de valores adicionais (processamento de imagens e serviços adicionais)."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Todos os planos de pagamento serão feitos mediante cobrança em Boletos ou Cartão de Crédito, que deverá ser cadastrado pelo USUÁRIO. O USUÁRIO não poderá cadastrar em sua conta Cartões de Crédito de terceiros, sob pena de exclusão do SITE e demais medidas legais cabíveis. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Para os planos mensais, o serviço será cobrado automaticamente, antecipadamente e não é reembolsável. Não haverá reembolsos ou créditos para meses parciais de uso do serviço, melhoria ou “rebaixamento” do serviço, o plano sempre ficará disponível para uso até o encerramento do período pago antecipadamente."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O pagamento para a MAPPA será automaticamente renovado ao final do período de assinatura, a menos que o USUÁRIO cancele sua Assinatura Paga por meio da página de assinatura antes do final do período da assinatura atual. O cancelamento entrará em vigor no dia seguinte ao último dia do período de assinatura atual e o USUÁRIO deixará de ter acesso ao Serviço. No entanto, se o USUÁRIO cancelar a Assinatura antes do final do período de assinatura atual, não será reembolsada nenhuma tarifa de assinatura que já tiver sido paga e o plano permanecerá disponível até o final do período pago antecipadamente. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA poderá, de tempos em tempos, alterar o preço dos Planos de Assinatura e outros serviços e informará quaisquer alterações de preço ao USUÁRIO com antecedência de ao menos trinta dias. As alterações de preços das Planos de Assinatura e outros serviços entrarão em vigor no início do próximo período de assinatura, depois da data da mudança de preço. O USUÁRIO aceitará o novo preço ao continuar usando o Serviço depois que a alteração de preço entrar em vigor. Se o USUÁRIO não concordar com as mudanças de preços, terá o direito de cancelar a assinatura do Serviço antes da cobrança da primeira mensalidade com o novo valor. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA poderá abrir em determinadas situações períodos de teste gratuito para novos usuários, sendo certo que o acesso destes usuários poderá ser cancelado a qualquer momento pela MAPPA sem qualquer aviso prévio. As especificações sobre o período de teste gratuito serão divulgadas pela plataforma no momento da adesão e cadastro do novo usuário."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v(" ACESSO À INTERNET")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA não se responsabiliza por falhas de comunicação, de internet, etc. O USUÁRIO é inteiramente responsável pela conexão à Internet forte o suficiente para a plena operação do SITE e/ou do Serviço."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v("CONTAS")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O SITE e/ou Serviço poderão ser utilizados somente por pessoas maiores de 18 (dezoito) anos que cumpram todos os requisitos previstos no TCDU. Além das pessoas consideradas por lei como incapazes, também serão impossibilitados de utilizar os serviços pessoas que tenham sido inabilitadas previamente. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O cadastro é gratuito, no entanto, os serviços oferecidos pela MAPPA serão cobrados de acordo com o estabelecido neste TCDU."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Cada USUÁRIO deverá ter apenas um cadastro no SITE. Caso seja identificada a existência de cadastros duplicados, a MAPPA poderá inabilitar de forma definitiva todos os cadastros do USUÁRIO no SITE, independentemente de qualquer aviso ou notificação."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O USUÁRIO declara que todas as informações que forneceu à MAPPA para fins de criação de uma conta (Informações de Registro) são verdadeiras, precisas, atuais e completas e sua falha em fornecer tal informação constituirá uma violação deste TCDU e poderá resultar na rescisão imediata de sua conta. O USUÁRIO atualizará suas Informações de Registro para mantê-las verdadeiras, precisas, atuais e completas; e manterá a confidencialidade de sua senha."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " Ao criar uma Conta, o USUÁRIO não deve se passar por outra pessoa, nem usar um nome sujeito aos direitos de qualquer outra pessoa sem autorização. O USUÁRIO também se compromete a não usar endereço de e-mail ou nome de usuário que seja ofensivo ou inadequado; nem permitir que qualquer outra pessoa use sua Conta."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " O USUÁRIO entende e concorda que será responsável por todas as atividades que ocorrem sob qualquer Conta criada para seu uso, mesmo se tais atividades não foram por ele cometidas. A MAPPA não se responsabiliza por qualquer perda ou dano resultante da sua falha em manter a confidencialidade da sua senha."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA poderá recusar qualquer solicitação de cadastro, bem como cancelar um cadastro previamente aceito que esteja em desacordo com este TCDU. Fica estabelecido que o cadastramento do interessado, uma vez aprovado e confirmado o pagamento, permitirá a utilização dos serviços do SITE disponíveis no plano contratado."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O conteúdo das informações inseridas no cadastro é de inteira responsabilidade do USUÁRIO, não sendo a MAPPA responsável pela correção ou verificação dos dados pessoais. O USUÁRIO garante e responde, em qualquer caso, civil e criminalmente, pela veracidade, exatidão e autenticidade dos dados pessoais cadastrados."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Independente do disposto acima, a MAPPA poderá, a qualquer tempo e a seu exclusivo critério, verificar os dados pessoais podendo para tanto, solicitar documentos e comprovantes do USUÁRIO para verificação das informações fornecidas."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Sem prejuízo de outras medidas, a MAPPA poderá, a qualquer tempo e, a seu exclusivo critério, advertir, suspender ou cancelar, temporária ou definitivamente, o cadastro de um USUÁRIO, suspendendo a prestação de seus serviços se: (i) o USUÁRIO descumprir as disposições do presente TCDU; (ii) descumprir com seus deveres de USUÁRIO; (iii) praticar atos fraudulentos ou dolosos; (iv) não puder ser verificada a identidade do USUÁRIO ou se verificada que qualquer informação fornecida por ele está incorreta; (v) qualquer atitude do USUÁRIO tenha causado algum dano a terceiros ou ao próprio SITE ou tenham a potencialidade de assim o fazer."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA RESERVA-SE O DIREITO DE EXCLUIR OU BLOQUEAR DADOS DO USUÁRIO SEM ACESSO POR UM PERÍODO SUPERIOR A 6 (SEIS) MESES, SEM PRÉVIO AVISO. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O USUÁRIO poderá, a qualquer momento, solicitar a exclusão de seu cadastro. Caso não existam pendências conhecidas, a MAPPA irá realizar a exclusão do cadastro, mantendo em seus arquivos as informações sobre transações realizadas."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Em caso de perda, extravio ou suspeita de utilização indevida de sua conta, login ou senha, o USUÁRIO deverá notificar a MAPPA imediatamente. Neste caso, a MAPPA irá bloquear o respectivo cadastro até identificar a melhor maneira de solucionar o problema."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta de um USUÁRIO a terceiros, assim como a criação de novos cadastros a USUÁRIO cujo cadastro original tenha sido cancelado unilateralmente pela MAPPA."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Serão eliminados apelidos ou nomes de usuário que guardem qualquer semelhança com o nome “MAPPA”, bem como os considerados ofensivos, ou que contenham dados pessoais do USUÁRIO ou alguma propaganda, URL ou endereço eletrônico."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [
          _vm._v(" LICENÇA LIMITADA PARA UTILIZAR O SITE E O SERVIÇO")
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " Nos termos deste TCDU, a MAPPA concede ao USUÁRIO licença limitada, não exclusiva, pessoal e intransferível para acessar e usar o SITE e/ou o Serviço. O SITE e/ou o Serviço somente podem ser utilizados para fins pessoais e não comerciais."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A interface, o conteúdo, o arranjo e a aparência do SITE e/ou Serviço, incluindo, as marcas registradas MAPPA e logotipos, desenhos, textos, ilustrações, imagens, dados, botões, interfaces de usuário, códigos fonte, códigos de objeto, know-how, produtos, programas, informações e outros conteúdos e qualquer compilação dos itens acima são propriedade da MAPPA, exceto quando indicado de forma diversa, e são protegidos contra cópias ou imitações. "
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [
          _vm._v("DA VIOLAÇÃO DO SISTEMA E DO SIGILO DE INFORMAÇÕES")
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que venha a interferir nas atividades e operações do SITE e/ou Serviço, descrições, contas ou seus bancos de dados. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas neste TCDU, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Toda informação ou DADOS PESSOAIS inseridos pelo USUÁRIO no SITE e/ou Serviço é armazenada em servidores ou meios magnéticos, ópticos ou de acordo com a evolução tecnológica, e a MAPPA se obriga a adotar as medidas necessárias para manter o sigilo e a segurança das informações, ficando estabelecido, entretanto, que não responderá por quaisquer prejuízos ocasionados pela violação das medidas necessárias de segurança e sigilo por terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de segurança para acessar as informações de usuários."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " O USUÁRIO se compromete a não violar qualquer lei ou regulamento aplicável em conexão com seu uso do SITE e/ou Serviço. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA pode cancelar a assinatura de um usuário se achar que o mesmo não está utilizando a plataforma dentro do que consideramos um uso justo. Nesse momento, o usuário será notificado, seu plano cancelado e sem direito de reembolso."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA não controla o conteúdo (imagens, dados, histórico de localização, etc.) enviado pelo USUÁRIO para o SITE e/ou Serviço, e, portanto, não garante e não será responsável por tais conteúdos. "
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA NÃO SE RESPONSABILIZA OU FORNECE GARANTIAS DOS DADOS GERADOS ATRAVÉS DO SITE E/OU SERVIÇO QUANTO À SUA ACURÁCIA, PRECISÃO, RESOLUÇÃO, AUSÊNCIA DE ERROS E CONFIABILIDADE."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA NÃO SE RESPONSABILIZA PELA QUALIDADE DOS MATERIAIS E DADOS CAPTADOS E PROVIDOS PELO USUÁRIO PARA PROCESSAMENTO ATRAVÉS DO SITE E/OU SERVIÇO. A BAIXA QUALIDADE DESTES DADOS PODE GERAR DADOS IMPRECISOS, SEM RESOLUÇÃO, SEM ACURÁCIA, INFORMAÇÕES QUE POSSAM NÃO CONDIZER COM A REALIDADE, ENTRE OUTROS PROBLEMAS, MOTIVO PELO QUAL A MAPPA NÃO SE RESPONSABILIZA PELA UTILIZAÇÃO DAS INFORMAÇÕES GERADAS PELO SITE E/OU SERVIÇO PELO USUÁRIO."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Todos os comentários, sugestões, ideias e outros envios que o USUÁRIO divulgar, enviar ou oferecer relacionado ao uso do SITE ou Serviço, constituirá numa cessão de todos os direitos de propriedade intelectual, e tais sugestões se tornarão de propriedade exclusiva da MAPPA."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " O USUÁRIO fornece seu consentimento irrevogável para monitoramento, acesso e divulgação de seus comentários e atividades no SITE e no Serviço."
          ),
          _c("br")
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v("APLICATIVOS DE TERCEIROS")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            'O SITE e o Serviço estão integrados com aplicativos, sites e serviços de terceiros ("Aplicativos de Terceiros") para disponibilizar ao USUÁRIO conteúdo, produtos e/ou serviços. Esses Aplicativos de Terceiros poderão ter seus próprios termos e condições de uso e políticas de privacidade, e o uso desses Aplicativos de Terceiros será regido por, e estará sujeito a, tais termos e condições, e às políticas de privacidade. O USUÁRIO compreende e concorda que a MAPPA não endossa e não é responsável pelo comportamento, recursos ou conteúdo de qualquer Aplicativo de Terceiro, ou por qualquer transação que você possa fazer com o provedor de tais Aplicativos de Terceiros.'
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [_vm._v("SERVIÇOS DE TERCEIROS")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " A MAPPA pode fornecer ferramentas através do serviço que permitem exportar informações, incluindo o conteúdo de usuário, para serviços de terceiros. Ao usar uma dessas ferramentas, o USUÁRIO aceita que a MAPPA possa transferir aquelas informações para as aplicações do serviço de terceiro. Estes serviços não estão sobre o controle da MAPPA, e portanto, esta não será responsável por qualquer uso de informações do USUÁRIO."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [
          _vm._v("REPRESENTAÇÃO E GARANTIA DE CONTEÚDO DE USUÁRIO")
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "O USUÁRIO é unicamente responsável pelas suas informações de usuário e pelas consequências de postar ou publicar essas informações. Ao publicar informações de usuário, este assume e garante que: é o criador e possuidor das informações, ou possui licenças necessárias, direitos, consentimentos e permissões para usa-las e autorizar a MAPPA e os usuários do serviço a usar e distribui-las; suas informações e o uso destas, como aqui contemplado, não irá: (i) infringir qualquer direito de terceiro, incluindo qualquer propriedade intelectual, privacidade ou direito de propriedade; ou (ii) caluniar, difamar ou invadir o direito à privacidade, publicidade ou outros direitos de propriedade de qualquer pessoa."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A MAPPA não esta sob nenhuma obrigação de editar ou controlar informações de usuário que este ou outros usuários postarem ou publicarem, e não será de modo algum responsável pelo conteúdo do usuário. A MAPPA pode, no entanto, a qualquer tempo e sem prévio aviso, ocultar, remover, editar ou bloquear qualquer informação de usuário que no julgamento da MAPPA violar esses termos ou que for censurável. O USUÁRIO aceita renunciar e renuncia qualquer remédio legal ou equitativo que você possa ter contra a MAPPA a respeito das informações de usuários."
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [
          _vm._v("LIMITAÇÃO DE RESPONSABILIDADE")
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " A MAPPA não se responsabiliza por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema do usuário ou de terceiros, inclusive relativos a pagamentos e transferências."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " A MAPPA não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo USUÁRIO em razão de falhas na internet, no sistema ou no servidor utilizado pelo USUÁRIO, ou aqueles decorrentes de condutas de terceiros, de caso fortuito ou de força maior. A MAPPA também não será responsável por qualquer vírus ou malware que possa atacar o equipamento do USUÁRIO em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos ou áudio."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " O USUÁRIO indenizará a MAPPA, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por seu descumprimento do presente TCDU e demais políticas do SITE e Serviço, ou pela violação de qualquer lei ou direito, incluindo honorários advocatícios."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " Ao aceitar o presente TCDU, o USUÁRIO reconhece e concorda que a única medida cabível por quaisquer problemas ou insatisfações com o SITE ou qualquer parte do serviço é encerrar a sua conta e descontinuar o uso."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Na medida máxima permitida pela lei, em nenhuma circunstância a MAPPA, seus responsáveis, acionistas, funcionários, agentes, diretores, subsidiárias, afiliadas, sucessores, cessionários, fornecedores ou licenciadores serão responsáveis por (a) quaisquer danos indiretos, especiais, incidentais, punitivos, exemplares ou consequentes; (b) qualquer perda de uso, dados, negócios ou lucros (sejam diretos ou indiretos) decorrentes, em todos os casos, do uso ou da incapacidade de usar o Serviço, os aplicativos de terceiros ou o conteúdo de aplicativos de terceiros, independentemente da teoria legal; ou (c) responsabilidade agregada quanto a todas as reivindicações relacionadas ao Serviço, aplicativos de terceiros ou conteúdo dos aplicativos de terceiros que sejam superiores aos valores pagos pelo USUÁRIO durante os doze meses anteriores em questão, na medida máxima permitida pela lei aplicável."
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            'O Serviço é fornecido "no estado em que se encontra" e "como disponível". A MAPPA renuncia a todas as representações e garantias, expressas, implícitas ou estatutárias, não expressamente definidas nestes termos, incluindo as garantias implícitas de comercialização, aptidão para um propósito específico e não violação. Adicionalmente, a MAPPA não faz nenhuma representação, garantia ou garantia relativa à confiabilidade, oportunidade, qualidade, adequação ou disponibilidade dos serviços ou de qualquer serviço ou produto pedido pelo uso dos serviços ou que os serviços serão ininterruptos.'
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " O USUÁRIO aceita defender, indenizar e manter a MAPPA e suas afiliadas inofensivas perante qualquer demanda, perda, responsabilidade, reclamações ou despesas (incluindo taxas de advogados) feitas contra estas por terceiros devido o uso do Serviço."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " A MAPPA empreenderá esforços razoáveis para manter o Serviço operacional. No entanto, determinadas dificuldades técnicas ou manutenção poderão, de tempos em tempos, resultar em interrupções temporárias. Na medida do permitido segundo a lei aplicável, a MAPPA reserva o direito, periodicamente e a qualquer momento, de modificar ou descontinuar, temporária ou permanentemente, funções e recursos do Serviço, com ou sem aviso, sem ser responsabilizada, com relação a qualquer interrupção, modificação ou descontinuação do Serviço ou de qualquer função ou recurso dele. O USUÁRIO compreende, concorda e aceita que a MAPPA não tem obrigação de manter, apoiar, fazer upgrade ou atualizar o Serviço, ou de fornecer todo ou qualquer conteúdo específico por meio do Serviço."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c17" }),
        _c("span", { staticClass: "c20" }, [_vm._v("DAS DISPOSIÇÕES GERAIS")])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " Este TCDU não gera nenhum contrato de sociedade, de mandato, franquia ou relação de trabalho."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " O uso comercial do endereço do SITE como marca, nome empresarial ou nome de domínio, bem como os conteúdos das telas relativas aos serviços do SITE, assim como os programas, bancos de dados, redes, arquivos que permitem que o USUÁRIO acesse e use sua conta são propriedade da MAPPA e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo autorização expressa da MAPPA."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            ' Para obter informações sobre suporte ao cliente com perguntas relacionadas a contas e a pagamentos ("Perguntas de Suporte ao Cliente"), envie um email para nosso departamento de atendimento ao cliente, usando o formulário de contato de Atendimento ao Cliente na seção Sobre Nós em nosso site. Empreenderemos esforços razoáveis para responder a todas as Perguntas de Suporte ao Cliente em um período razoável, mas não fornecemos garantias de qualquer tipo de que as Perguntas de Suporte ao Cliente serão respondidas dentro de qualquer período específico e/ou de que poderemos responder satisfatoriamente a tais perguntas.'
          )
        ])
      ]),
      _c("p", { staticClass: "c7" }, [
        _c("span", { staticClass: "c5" }, [
          _vm._v("LEI APLICÁVEL / JURISDIÇÃO")
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            " A MAPPA está localizada e registrada no Brasil. Ao utilizar o SITE e/ou Serviço o USUÁRIO ratifica que tem conhecimento de que o serviço está sendo prestado no Brasil."
          )
        ])
      ]),
      _c("p", { staticClass: "c0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Este TCDU será interpretado sempre em Português do Brasil e regido pelas leis vigentes na República Federativa do Brasil. Para dirimir eventuais dúvidas ou questões relativas ao presente TCDU, as partes elegem o Foro da Comarca de Florianópolis - SC, com exclusão de qualquer outro."
          )
        ])
      ]),
      _c("p", { staticClass: "c18" }, [_c("span", { staticClass: "c3" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }